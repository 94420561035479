body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPaper-rounded {
  max-width: 800px;
  margin: auto;
  padding: 2rem;
}

.classesroot {
  width: 100%;
  margin: auto;
  max-width: 800px;
  text-align: center;
}

.classespaper {
  padding: 10px;
}

.extramargin {
  margin-top: 6rem;
}

.datepicker {
  padding-top: 0px !important;
}

.centertextalign {
  text-align: center;
}

.red-text-center {
  text-align: center;
  color: #f44336;
  font-weight: 300;
}

.hcborder {
  background: #e8e8e8;
  border-radius: 10px;
  margin-bottom: 25px;
  padding: .1rem 1rem 1rem;
  margin-top: 1rem;
}

.SignUpForm-container-167 {
  max-width: inherit !important;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
  margin: auto;
}
.MuiTableRow-root button {
  margin: 0px !important;
}
.automargin{
  text-align:center;
  margin-top: 20%;
}
.jss167 {
  max-width: 100% !important;
}
@media (min-width: 0px){
  .MuiContainer-maxWidthXs {
      max-width: 480px !important;
      padding: 0 !important;
  }
}