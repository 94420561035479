@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/Roboto-Regular.ttf');
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.doctor_signup:hover{
  text-decoration: none !important;
}
.MuiPaper-rounded{padding: 1rem;}

.editable_field{
  width: 40% !important;
  margin-top: 0 !important;
}
.editable_field1{
  width: 88% !important;
}
.editable_field input, .editable_field textarea, .editable_field .MuiSelect-select {
  border-radius: 4px;
  padding: 8px;
}
.MuiFab-root{
  width: 40px !important;
  height: 40px !important;
  position: absolute !important;
  bottom: -2rem;
  right: 2rem;
}
.MuiBottomNavigation-root{
  align-items: center;
}
.header_logo img {
  width: auto;
  height: 40px;
}
.userphoto:after{
  content: 'Edit Photo';
  position: absolute;
  bottom: 0;
  background-color: #009688;
  width: 100%;
  padding: .6rem 0 .8rem;
  font-size: .9rem;
  text-align: center;
  background-color: rgba(0, 187, 190, 0.5);
  color: #fff;
}
.ant-alert-error {
  background-color: #f44336 !important;
  border: #f44336 !important;
}
.ant-alert-error span{
  color: #fff !important;
}
.profilePage .editable_field label{
  top: -10px
}
.profilePage .editable_field label.MuiFormLabel-filled, .profilePage .editable_field label.MuiInputLabel-outlined.Mui-focused
{
  top: 0
}
.top_label label.MuiFormLabel-filled, .top_label label.MuiInputLabel-outlined.Mui-focused{
  top: 15px;
}
.top_label{
  padding-bottom: 16px;
  border-radius: 5px 0px;
}
.filter_search_box label{
  position: relative;
  transform: translate(14px, 27px);
  text-align: left;
}
.filter_search_box input{
  padding: 0 !important;
}
.emr .MuiExpansionPanelSummary-root.Mui-expanded{
  min-height: 0;
}
.emr .MuiExpansionPanelSummary-content.Mui-expanded{
  margin: 0
}
a{
  color: #008a8c !important;
}
.scheduler_update_time p{width: 100%;}
.scheduler_update_time{
  width: 80% !important;
  height: min-content;
  display: flex;
  margin: 2rem 0;
  align-items: center;
}
.automargin {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: 0;
}
/* .video_app video{
  width: 100%;
  max-height: 75vh;
}
.video_app .own_video video{
  width: 100px;
  float: right;
  position: absolute;
  z-index: 1;
  right: 14px;
  bottom: 15px;
} */
.k-message, .k-author{
  background-color: #fff !important;
  box-shadow: 0 1px 2px rgba(0,0,0,0.16);
  padding: 5px 10px;
  border-radius: 6px;
  margin: 5px 0 !important;
}
.k-author{
  background-color: unset !important;
  box-shadow: unset;
  padding: 0;
}
.k-message-group .k-bubble{
  box-shadow: unset !important;
  background-color: unset !important;
  border: unset !important;
  color: black !important;
  padding: 8px 0 !important;
}
.k-message-group .k-author{
  font-weight: 700;
  font-size: 14px;
}
.k-chat .k-message-group:not(.k-alt) .k-message-time{
  margin-left: 16px !important;
}
.k-chat .k-message-box{
  border-color: #ffffff00 !important;
  background-color: #ffffff00 !important;
}
.k-chat .k-message-box div{
  display: flex;
  background-color: #fff;
  width: 100%;
  padding: 5px 16px;
  border-radius: 100px;
  margin-right: 15px;
}
.k-chat .k-message-box > .k-button{
  margin: auto;
  color: #fff !important;
  border-radius: 100px;
  background-color: #00bbbe !important;
}
.chatHeader{
  margin-bottom: 0;
  padding: 0 10px !important;
  color: #00bbbe;
  font-weight: 600;
  font-size: 15px;
  text-align: left;
  background: #fff;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
}
.videoHeader
{
  min-width: 400px;
}
.chatHeader p
{
  display: flex;
  margin-bottom: 0;
}
.k-chat{
  border-style: hidden !important;
  max-width: unset !important;
  background: #ededed !important;
  height: calc(100vh - 216px) !important
}
.k-chat .k-timestamp{
  display: none;
}

.k-chat .k-message-list-content{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.k-chat .k-message-list-content>*+*{
  margin-top: 10px;
}
.clientChat{
  margin-right: 5px;
}
.k-chat .k-message-box div .k-button svg{
  color: #a8a8a8
}
.k-chat .k-message-box div .k-button{
  transform: rotate(45deg);
}
.chatToCall{
  color: #00bbbe;
  margin: auto;
  cursor: pointer;
}
.shadow{
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.appointmentFiles .MuiExpansionPanelSummary-content.Mui-expanded{
  margin: 12px 0 !important
}
.appointmentFiles .MuiExpansionPanelSummary-content{
  flex-grow: unset;
}
.videoSection .participant{
  width: 100%;
}
.videoSection video{
  height: 100%;
  width: 100%;
  z-index: 1;
}
.videoSection #localVideo div:not(:empty) + span{
  display: none
}
.mobileVideo button{
  background: #00bbbe;
  color: #fff;
  margin-left: 8px;
}
.callVideo{
  display: flex;
}
.callVideo button{
  min-width: 0px;
    background: #00bbbe;
    padding: 6px;
    border-radius: 100%;
    margin: 5px 0 5px 4px;
}
.callVideo button svg{
  color: #fff
}
.mobileChatScreen .k-chat .k-message-box{
  position: fixed;
  bottom: 0;
  background-color: #e5e5e5 !important;
  width: 100%;
}
.mobileChatScreen .k-widget.k-chat{
  height: calc(100vh - 120px);
}
.mobileChatScreen .k-message-list-content{
  margin-bottom: 70px;
  width: 100%;
}
#remoteParticipant .participant{
  width: 100%;
}
#localParticipant video{
  -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.NoteModal .ant-modal-title{
  font-weight: 700;
}
.add-to-home-banner{
  width: calc(100vw - 48px) !important;
    padding: 10px 0;
}
.negativeMarign{
  margin: -88px -24px;
}
.notLoggedInPages .label span{
  color: #795bb5 !important;
  font-size: 17px;
  font-weight: 700 !important;
}
.notLoggedInPages .label svg{
  color: #795bb5;
}
.notLoggedInPages .label svg text{
  fill: #fff;
  font-weight: 700 !important;
}
.notLoggedInPages .label svg.completed, .notLoggedInPages label.MuiFormLabel-root.Mui-focused{
  color: #3f51b5 !important;
}
.notLoggedInPages .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #3f51b5;
}
.notLoggedInPages .MuiInput-underline:after{
  border-bottom: 2px solid #3f51b5;
}
.notLoggedInPages .MuiButton-containedPrimary:hover{
  background-color: #795bb5 !important;
}
.DcotorAvailability .MuiFab-secondary, .profilePage .MuiFab-secondary{
  width: auto !important;
  border-radius: 25px;
  padding: 0 20px;
  bottom: -1.25rem;
}
.MuiAlert-message a {
  background-color: #00bbbe;
  padding: 5px;
  border-radius: 5px;
  color: #ffffff !important;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}
.MuiAlert-filledInfo {
  background-color: #795bb2 !important;
  margin-bottom: 10px;
}
.MuiOutlinedInput-root.Mui-error fieldset.MuiOutlinedInput-notchedOutline
{
  border-width: 2px;
  border-style: solid;
}
p.MuiFormHelperText-root.Mui-error{
  color: #f44336
}
p.smsnote{
  color: #795bb5;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  /* margin: auto; */
}

.photoClasses .MuiInputLabel-outlined.MuiInputLabel-shrink{
  transform: translate(0px, -20px) scale(0.75);
}
.emr .MuiPaper-rounded{
  max-width: 100% !important;
}
.doctorSearch .MuiOutlinedInput-input{
  padding: 9.5px 14px !important;
}
.FiltersPage .MuiExpansionPanelDetails-root{
  padding: 0px 16px 8px !important;
}
.FiltersPage .MuiExpansionPanelSummary-content{
  margin: 0 !important;
}
.FiltersPage .MuiExpansionPanelSummary-expandIcon{
  padding: 0;
}
.appointmentScheduler .MuiToolbar-regular button{
  /* color: #fff; */
  /* font-size: 1.2rem; */
  font-weight: 600;
}

.appointmentScheduler .MuiToolbar-regular{
  /* background-color: #854da4; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    /* color: #fff; */
}
.appointmentScheduler > div > div:last-child{
  border: 1px solid #e7e7e7;
}
.appointmentSlotsModel div:not(:last-child){
  display: none;
}
/* Popover override */
.MuiPopover-root{
  z-index: 2000 !important;
}
/* 
Styling for comfirm-alert-box-react
*/
.react-confirm-alert-overlay{
  z-index: 9999 !important;
  background-color:rgba(170, 169, 151, 0.7)!important;;
}

.k-message {
  background-color: transparent !important;
  box-shadow: unset !important;
  width: 100%;
}
.k-message .k-bubble {
  width: 100% !important;
}
.k-message >div {
  width: 100% !important;
}
.k-message .k-bubble div {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  max-width: 80% !important;
  width: fit-content !important;
}
.k-message .k-bubble.k-systemMessage div{
  border-radius: 4px;
  box-shadow: unset !important;
  background-color: unset !important;
  max-width: 100% !important;
}
.k-message .k-bubble.k-systemMessage p{
  margin-bottom: 0 !important;
}
.k-message-group.k-no-avatar{
  width: 100% !important;
  max-width: 100% !important;
}
.ModelToHideFooter .ant-modal-footer{
  display: none !important;
}
.hideNotesPage{
  display: none !important;
}

@media (max-width: 560px){
  .k-chat .k-message-box{
    position: fixed;
      bottom: 0;
      background-color: #e5e5e5 !important;
      width: 100%;
  }
}
.schedularModels .ant-modal-header{display: none;}
.schedularModels .ant-modal-content{border-radius: .3rem;}
.schedularModels .react-datepicker-popper{transform: translate3d(0px, 15px, 0px) !important}
.apoointmentOptionsHoldAmount input{color: #fff; border-bottom: 2px solid #fff}
.doctorDashboardScheduler .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters > div{order: 2}
.doctorDashboardScheduler .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters > div:last-child{display: none;}
.doctorDashboardScheduler .calendarType{display: flex}
.availabilityDateCursor input{cursor: pointer;}
.AvailabilityCalendarCellOnDashBoard > div {display: none;}
.m-0{margin: 0 !important}
.mayersClass img{height: auto !important;}
@media (min-width: 560px){
  .doctorDashboardScheduler .calendarType{position: absolute; right: 47.5%; top: -2.7rem;}
  .doctorDashboardScheduler .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters{padding: 0 41% 0 0}
  .doctorDashboardScheduler > div:nth-child(2){height: calc(100% - 3rem) !important;}
}
@media (max-width: 560px){
  #AppointmentOverLayChildDiv > div{
    height: auto;
    padding-bottom: 2rem;
  }
}

.btnCustomClass{
  border-radius: 0.5rem !important;
  text-transform: none !important;
  padding: 12px !important;
  min-width: 120px !important;
}

.lightBlueClasstbn{
  color: rgb(78,202,200) !important;
  background-color: rgb(216,243,243) !important;
}
.lightGreenClasstbn{
  background-color: rgb(216,245,223) !important;
  color: rgb(26,195,83) !important;
}
.lightRedClasstbn{
  background-color: rgb(253,230,230) !important;
  color: rgb(251,174,174) !important;
}
.lightGreyClasstbn{
  background-color: rgb(240, 242, 245) !important;
  color: rgb(110,127,127) !important;
}
.EMRSortingClass ul{
  padding-left: 0;
}
.materialPhoneNumber button{
  width: 0
}
.subaccountUpdatedModel .ant-modal-body{
  padding: 0
}